@mixin common-styles {
    display: flex;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    color: rgba(11, 46, 105, 1);
}

@mixin value-styles {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.list_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 7px 40px 11px 21px;
    border-bottom: 2px solid rgba(140, 159, 195, 0.29);
}

.projects_count {
    @include common-styles;
    flex: 1;
}

.employee_name {
    @include common-styles;
    flex: 2;

    &__value {
        @include value-styles;
    }
}

.employee_duration {
    @include common-styles;
    flex: 2;
    justify-content: flex-end;

    &__value {
        @include value-styles;

        span {
            color: rgba(41, 121, 255, 1);
        }
    }
}

.project_card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 18px 40px 18px 21px;
    background-color: white;
    margin-bottom: 8px;
}

.project_info_col {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
}

.project_name {
    flex-direction: column;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
}

.pm_name {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    cursor: pointer;

    span {
        color: rgba(35, 129, 191, 1)
    }
}

.employee_list_col {
    display: flex;
    flex-direction: column;
    flex: 4;
    padding-top: 16px;
}

.duration_item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-top: 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    &__value {
        @include common-styles;
    }

    &__label {
        @include common-styles;
    }
}