.pm-project-employee-list {
    box-sizing: content-box;
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            color: rgba(115, 115, 115, 1);
            font-style: normal;
            font-weight: 500;
            font-size: .925rem;
            background-color: #FFFFFF;

            th {
                padding: 1rem;
                border-bottom: 2px solid rgba(204, 214, 235, 1);
                width: 16.6%;
                text-align: center;

                &:first-of-type {
                    text-align: start;
                    font-weight: 500;
                }

                &:last-of-type {
                    text-align: end;
                }
            }
        }
    }

    tbody {
        font-size: .9rem;

        tr {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);

            td {
                padding: 1rem;
                width: 16.6%;
                text-align: center;

                &:first-of-type {
                    text-align: start;
                }

                &:nth-of-type(5) {
                    display: flex;
                    justify-content: center;
                }

                &:last-of-type {
                    text-align: end;
                }
            }
        }
    }
}

.project_report_name {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 18.75px;
    color: #0B2E69;
    padding: 2rem .5rem;
}

.sub_total_row,
.total_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
    font-weight: 500;
}

.sub_total_title,
.total_title {
    width: 20%;
    text-align: start;
}

.sub_total_duration,
.total_duration {
    width: 20%;
    text-align: end;
}

.sub_total_row {
    background-color: #FFFAF1;

    .sub_total_title {
        font-size: .875rem;
    }

    .sub_total_name {
        font-size: .875rem;
    }

    .sub_total_duration {
        font-size: .875rem;
    }
}

.total_row {
    background-color: #F5FFF6;

    .total_title {
        font-size: 1.2rem;
    }

    .total_duration {
        font-size: 1.2rem;
    }
}