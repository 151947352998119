/* DatePicker with calendar icon at the beginning */

.pickerIconEnd {
  width: 153px;
  height: 24px;
  border: 0.5px solid #2979ff;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 120px;
  background: url("../../icons/CalendarIcon.svg") no-repeat;
  background-position-x: 130px;
  background-position-y: center;
}

/* DatePicker with calendar icon at the end */
.pickerIconStart {
  font-size: 14px;
  line-height: 16px;
  width: 153px;
  height: 24px;
  border: 0.5px solid #2979ff;
  box-sizing: border-box;
  border-radius: 2px;
  background: url("../../icons/CalendarIcon.svg") no-repeat;
  background-position-x: 5px;
  background-position-y: center;
}

.calendar_wrapper {
  position: relative;
  background: #ffffff;
  left: -135%;
  bottom: -170px;
  border-radius: 10px;
}

.calendar_datePicker {
  position: absolute;
  left: 15.75%;
  bottom: 12.32%;
  background: #ffffff;
  border-radius: 10px;
}

.pickerTwoDates {
  width: inherit;
  height: 24px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 10px;
  //margin-right: 120px;
  background: url("../../icons/CalendarIcon.svg") no-repeat;
  background-position-x: 5px;
  background-position-y: center;
}