.select_horizontal_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select_vertical_wrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.select_title {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #878787;
  margin-right: .5rem;

  &__empty {
    @extend .select_title;
    color: #FF0000;
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}