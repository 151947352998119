.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 46, 105, 0.31);
}

.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  min-height: 100px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 73px rgba(32, 32, 35, 0.2);
  width: fit-content;
  height: fit-content;
  padding: 0 1.6875rem;
}

.modal_header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;

  &>*:not(:last-child) {
    margin-right: 25px;
  }

  &.closeRight {
    justify-content: end;
    margin: 0;
  }
}

.modal_header_title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.modal_body {
  position: relative;
  height: fit-content;
  border: 1px solid #ced8ec;
  border-radius: 2px;
  padding: 1.25rem;

  @media screen and (max-width: 768px) {
    height: 80%;
  }
}

.modal_footer {
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0 0 1rem 0;

  &>*:not(:last-child) {
    margin-right: 40px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-main {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: .5rem;
  padding: 0;
  position: absolute;

  & label {
    margin-left: 0;
  }
}

.form_block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.25rem;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
}

.divider {
  margin: 0 0 20px 0;
  height: 1px;
  width: calc(100% + 40px);
  background: #CBD0DA;
  margin-left: -20px;
}

.form_row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 213px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1.25rem;
  }
}

.checkbox_input {
  display: flex;
  align-items: center;
  margin-left: -30px;

  @media screen and (max-width: 768px) {
    margin-left: 122px;
  }

  span:nth-of-type(2) {
    font-weight: 400;
    font-size: 12px;
    color: #737373;
    transform: translateY(42%);
    margin-left: -100px;

    @media screen and (max-width: 768px) {
      margin-left: -118px;
    }
  }
}

.rate_input {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  input {
    margin-right: auto;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    color: #737373;
    width: 120px;
  }

  .rate {
    width: 89px;
  }
}

.input_label {
  display: flex;
  align-items: center;
  width: 240px;

  span {
    font-weight: 400;
    font-size: 12px;
    color: #737373;
    margin-right: 20px;
    min-width: 100px;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
}

.input_label_name {
  font-weight: 400;
  font-size: 12px;
  color: #737373;
  margin-right: 20px;
  min-width: 100px;
}

.date_input {
  display: flex;
  align-items: center;
}

.date_input_title {
  font-weight: 400;
  font-size: 12px;
  color: #737373;
  width: 186px;
}

.required {
  color: #f40000;
  font-weight: 500;
}

.rate,
.working_involvement,
.date_input_field {
  height: 1.5rem;
  border: 0.3px solid rgba(140, 159, 195, 0.29);
  box-sizing: border-box;
  font-size: 0.875rem;
  border-radius: 2px;
  outline: none;
  padding-left: 0.5rem;

  &:focus {
    border: 2px solid #2979FF;
    background: white
  }
}

.working_involvement,
.rate {
  background: rgba(140, 159, 195, 0.17);
}

.working_involvement {
  width: 12rem;
}

.date_input_field {
  width: 10rem;
  background: url("../../../icons/CalendarIcon.svg") no-repeat,
    rgba(140, 159, 195, 0.17);
  background-position-x: 5px;
  background-position-y: center;
}

.data_input {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 0 12px 0;
}

.validationMessage {
  position: absolute;
  color: red;
  font-size: 12px;
  margin-top: 4px;
  bottom: 6px;
  left: 140px;
  font-weight: 500;
}