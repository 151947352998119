.reports {
  &__filter {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @media screen and (max-width: 1070px) {
      flex-direction: column;
      gap: 2rem;
      .reports__filter__block__col--action-button {
        padding-top: 0;
      }
    }

    &__block {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      &__col--wide,
      &__col--narrow {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;

        &:empty {
          display: none;
        }
      }
      &__col--flex-start {
        display: flex;
        justify-content: flex-start;
      }

      &__col--action-button {
        padding-top: 14px;
      }

      &__col--narrow:empty {
        display: none;
      }

      &__col--wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        &:empty {
          display: none;
        }
      }
    }
  }

  &__date {
    &--wrapper {
      min-width: 7.5rem;
    }

    &--input {
      width: 100%;
      height: 1.875rem;
      border: 0.3px solid rgba(140, 159, 195, 0.29);
      box-sizing: border-box;
      font-size: 0.875rem;
      padding: 0.25rem;
      border-radius: 2px;
      outline: none;
      background:
        url('../../icons/CalendarIcon.svg') no-repeat,
        rgba(250, 253, 255, 1);
      background-position-x: 4px;
      background-position-y: center;
      margin-top: 0.5rem;
    }

    &--title {
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: #878787;
    }
  }
}

.filter-panel-report-button {
  margin-top: auto;
  width: 156px;
}
