.container {
    margin-left: 5.625rem;
    padding: 0 2rem 2rem calc(2rem + 10px);
    animation: fadeIn;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    transition: all .5s ease;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-width: 724px;

    @media screen and (max-width: 1280px) {
        margin-left: 0;
        padding: 0 1rem 2rem 1rem;
        width: calc(100% - 2rem);
    }

    @media screen and (min-width: 1670px) {
        width: 77vw;
    }
}

.circularProgressBarTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #2979FF;
}

.circularProgressBarValue {
    font-style: normal;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: #737373;
}

.datePicker {
    display: flex;
    flex-direction: column;
    height: 22rem;
    padding: 1rem 2rem;
    background: #FFFFFF;
    box-shadow: 0px 35px 73px rgba(32, 32, 35, 0.1);
    border-radius: 10px;
    position: relative;

    @media screen and (max-width: 1024px) {
        justify-content: flex-start;
        height: 95%;
        padding: 1rem;
    }
}

.datePickerWarning {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #878787;
    margin-right: .5rem;
    position: absolute;
    right: 2rem;
    bottom: -1.25rem;
}

.datePickerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.275rem;
}

.datePickerSummary {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.datePickerSummaryButton {
    display: flex;
    align-items: center;
    gap: .5rem;

    &:nth-of-type(2) {
        padding-left: 1rem;
        width: 12rem;
        justify-content: flex-end;
    }
}

.datePickerSummaryCol {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #0B2E69;
}

.datePickerInput {
    box-sizing: border-box;
    border: 1px solid #DEE3EE;
    border-radius: 3px;
    outline: none;
    width: 5.875rem;
    height: 100%;
    padding: .5rem;
}

.labelEmpty {
    margin: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding-top: 2rem;

    @media screen and (max-width: 1280px) {
        display: flex;
        justify-content: space-between;
        gap: 16px;
    }
}

.subheader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding-left: .575rem;
}

.progressBarContainer {
    display: flex;
    gap: 16px;
    height: 180px;

    @media screen and (max-width: 960px) {
        gap: 24px;
        height: 112px;
    }

    @media screen and (max-width: 1024px) {
        gap: 24px;
        height: 132px;
    }
}

.progressBar {
    max-width: 345px;
    padding: 0 1.5rem 2rem 1.5rem;

    @media screen and (max-width: 1024px) {
        max-width: 25%;
        padding: 0;
    }

    @media screen and (min-width: 1640px) {
        padding: 0 3rem 2.5rem 3rem;
    }
}

.form {
    padding: 1rem 2rem;
    background: #FFFFFF;
    box-shadow: 0px 35px 73px rgba(32, 32, 35, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media screen and (max-width: 1024px) {
        height: 95%;
    }
}

.formContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    @media screen and (min-width: 1670px) {
        gap: 1.25rem;
    }
}

.formContentCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    height: 21.75rem;

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: fit-content;
    }
}

.formFooter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.checkBoxWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;
    margin-top: auto;
}

.data {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;

    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}

.textAreaWrapper {
    box-sizing: border-box;
    width: 100%;
    height: 17rem;
}

.textArea {
    box-sizing: border-box;
    outline: none;
    border: 1px solid #DEE3EE;
    border-radius: 2px;
    padding: .5rem;
    resize: none;
    width: 100%;
    height: 100%;
}

.textAreaLabel {
    padding: 0;
    margin: 0;
}

.textAreaTitle {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #878787;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-left: 0;
    margin-bottom: .425rem;
}

.textAreaLength {
    color: #BCBCBC;
    font-size: .75rem;
    margin-top: .5rem;
}

.itemTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: #0B2E69;

    &:not(:first-of-type) {
        margin-top: 1rem;
    }
}

.itemTxtRegular {
    font-style: normal;
    font-weight: 400;
    font-size: .9rem;
    line-height: 19px;
    color: #171717;
    margin-top: .25rem;
}

.itemTxtRed {
    @extend .itemTxtRegular;
    color: #DC1919;
}

.itemWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

.itemWrapperCol {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.buttonArea {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expandDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.3px solid rgba(140, 159, 195, 0.29);
    border-bottom: 0.3px solid rgba(140, 159, 195, 0.29);
    padding: 24px 0;
    cursor: pointer;
}

.expandDetailsTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #2979FF;
}