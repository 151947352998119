//DataPairStyle
.data_pair {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 7.75rem;
}

.data_pair_title {
  color: #737373;
  font-size: 12px;
  line-height: 14px;
  margin-right: 0;
      padding: 0;

  &--report {
    @extend .data_pair_title;
    padding-top: .15rem;
  }
}

.data_pair_value {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 15px;
  padding: 0 1rem;
}

.data_pair_placeholder {
  font-size: 11px;
  color: #D3D3D3;
  padding: 0;
}