.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  h2 {
    text-align: center;
    font-family:
      IBM Plex Mono,
      monospace;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    color: #ffffff;
    width: 270px;
    margin-top: 24px;
  }

  p {
    font-family: IBM Plex Mono, monospace;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 24px;
    color: #ffffff;
    text-align: center;
    width: 300px;
  }
}
