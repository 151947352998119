@keyframes moveImage {
    0% {
        top: calc((100vh - 180px) / 8);
    }

    50% {
        top: calc((100vh - 180px) / 8 - 32px);
    }

    100% {
        top: calc((100vh - 180px) / 8);
    }
}

@keyframes fadeInByLeft {
    0% {
        opacity: 0;
        left: 24px;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

@keyframes fadeInByLeftAndRight {
    0% {
        opacity: 0;
        left: 114px;
        right: -24px;
    }

    100% {
        opacity: 1;
        left: 90px;
        right: 0;
    }
}

@keyframes fadeInByLeftAndRightNormal {
  0% {
      opacity: 0;
      left: 24px;
  }

  100% {
      opacity: 1;
      left: 0;
  }
}


@keyframes fadeInBlock {
    0% {
        opacity: 0;
    }

    65% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}