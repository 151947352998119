$overlay: rgba(0, 0, 0, 0.5);
$background: #1b1c1e;
$text: #ffffff;
$primary: #2979ff;
$description_line_height: 26px;

.popup {
  position: inherit;
  &_overlay {
    z-index: 1000;
    background-color: $overlay;
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &_content {
    z-index: 9999;
    background-color: $background;
    color: $text;
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 650px;
    max-height: 85vh;
    padding: 64px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    text-align: center;
    border: 1px solid transparent;
    border-image: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.51),
        rgba(255, 255, 255, 0.07)
      )
      1;

    &:focus {
      outline: none;
    }
  }
  &_success-icon {
    margin-bottom: 8px;
  }
  &_title {
    color: $text;
    font-family: IBM Plex Mono;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0px;
  }
  &_description {
    color: $text;
    font-family: IBM Plex Mono;
    font-size: 18px;
    font-weight: 400;
    line-height: $description_line_height;
    letter-spacing: 0px;
    text-align: center;
    &_empty {
      height: $description_line_height;
      width: 100%;
    }
  }
  &_actions {
    display: flex;
    gap: 32px;
    justify-content: center;
    padding-top: 90px;
  }
  &_button {
    font-family: IBM Plex Mono;
    cursor: pointer;
    border: unset;
    color: $text;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    padding: 16px 0;
    font-size: 24px;
    font-weight: 500;
    &_cancel {
      border: 1px solid rgba(255, 255, 255, 0.51);
      background: rgba(255, 255, 255, 0.05);
    }
    &_confirm {
      background-color: $primary;
    }
  }
}
