.pagination {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 2rem 0;
}
  
.pagination-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 80%;
}