.vacations-list {
  box-sizing: content-box;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;

  thead {
    tr {
      color: #0b2e69;
      font-style: normal;
      font-weight: 500;
      font-size: 0.925rem;

      th {
        padding: 1rem;
        border-bottom: 2px solid rgba(204, 214, 235, 1);
        text-align: start;
        width: 20%;

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          text-align: center;
        }

        &:last-of-type {
          text-align: end;
        }
      }
    }
  }

  tbody {
    font-size: 0.9rem;

    tr {
      background: #f3f7ff;

      td {
        padding: 1rem;
        width: 20%;

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          text-align: center;
        }

        &:last-of-type {
          width: auto;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.table_btn_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
}
