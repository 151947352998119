.root {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .expandableItem {
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    max-height: 38px;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid transparent;
    border-image: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.51),
        rgba(255, 255, 255, 0.07)
      )
      1;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;

    .taskItem {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .taskName {
        padding: 6px 0;
        font-family:
          IBM Plex Mono,
          monospace;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #ffffff;
      }

      .taskInfo {
        display: flex;
        gap: 16px;
        align-items: center;

        .taskBcoinValue {
          display: flex;
          gap: 8px;
          align-items: baseline;
          font-family:
            Space Grotesk,
            sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 1px;
          color: #ffffff;

          span {
            font-family:
              IBM Plex Mono,
              monospace;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
          }
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          background-color: #343538;

          svg {
            transition: all 0.3s ease;
          }
        }

        .iconExpanded {
          svg {
            transform: rotate(180deg);
            transition: all 0.3s ease;
          }
        }
      }
    }

    .taskDescription {
      display: flex;
      margin-top: 24px;
      font-family:
        IBM Plex Sans,
        monospace;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .expanded {
    max-height: 200px;
    transition: all 0.3s ease;
  }
}
