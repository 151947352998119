.pm-projects-list {
    box-sizing: content-box;
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            color: #0b2e69;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            background-color: rgba(250, 250, 250, 1);

            th {
                background-color: rgba(250, 250, 250, 1);
                padding: 1rem;

                &:first-of-type {
                    border-left: 5px solid rgba(250, 250, 250, 1);
                    text-align: start;
                    width: 20%;
                }

                &:last-of-type {
                    border-right: 5px solid rgba(250, 250, 250, 1);
                    text-align: end;
                    width: 20%;
                }
            }
        }
    }

    tbody {
        font-size: .9rem;

        tr {
            cursor: pointer;

            td {
                border-bottom: 5px solid rgba(250, 250, 250, 1);
                padding: 1rem;
                text-align: center;

                &:first-of-type {
                    border-left: 5px solid rgba(250, 250, 250, 1);
                    text-align: start;
                    width: 20%;
                }

                &:last-of-type {
                    border-right: 5px solid rgba(250, 250, 250, 1);
                    text-align: end;
                    width: 20%;
                }
            }
        }
    }
}