.content {
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: #1d1f23;
  z-index: 0;
  top: -100px;
  padding-top: 100px;
  height: calc(100vh - 233px);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
  scrollbar-width: none;
  mask-image: linear-gradient(
    to bottom,
    rgba(243, 243, 243, 0.82) 90%,
    rgba(240, 240, 240, 0.17) 100%
  );

  ::-webkit-scrollbar {
    display: none;
  }

  .header {
    display: flex;
    position: fixed;
    width: 100%;
    padding: 16px 0 16px 72px;
    background-color: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(50px);
    z-index: 100;
  }

  .tabContent {
    display: flex;
    flex-direction: column;
    padding: 56px 72px;
    margin-top: 114px;
    height: fit-content;
  }
}
