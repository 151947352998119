.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    .modal__content {
        h3 {
            margin-bottom: 8px;
        }

        font-size: 14px;
        text-align: justify;
        padding: 20px;
        border-radius: 12px;
        background-color: white;
        width: 40%;
        transform: scale(0.5);
        transition: 0.4s all;

        &.active {
            transform: scale(1);
        }
    }
}