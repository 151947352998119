.tabsContainer {
  display: flex;
  border: 1px solid #ffffff43;
  overflow: hidden;

  .tab {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    min-width: 232px;
    height: 80px;
    position: relative;
    padding: 0 32px;
    border-bottom: 4px solid transparent;
    color: #ffffffa0;
    cursor: pointer;

    .tabName {
      display: flex;
      align-items: center;
      position: absolute;
      gap: 12px;
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 400;
      font-size: 20;

      &_active {
        font-weight: 500;
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 24px;
      background-color: #898989;
    }

    &:last-child::after {
      display: none;
    }

    span {
      display: flex;
      background-color: #ffffff19;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 28px;
      color: #ffffffb3;
      border-radius: 100px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }

    .badgeActive {
      background-color: #2979ff;
      color: #fff;
    }
  }

  .active {
    border-bottom: 4px solid #2979ff;
    color: #fff;
  }
}
