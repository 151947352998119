.pm-info{
   width: 65%;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   margin-top: 12px;
   gap: 24px;

   &__text{
      font-size: 13px;
      line-height: 16px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 12px;
      
      span {
         font-size: 12px;
         line-height: 16px;
         color: #737373;
      }
   }
}