.text-area-field {
   display: flex;
   width: inherit;

   &>span {
      color: #737373;
      font-size: 12px;
      line-height: 14px;
      margin-right: 15px;
      padding-top: 8px;
   }

   &>textarea {
      padding: 8px;
      outline: none;
      width: inherit;
      background: rgba(140, 159, 195, 0.17);
      border: 0.3px solid rgba(140, 159, 195, 0.29);
      line-height: 14px;
      font-size: 12px;
      border-radius: 5px;
      color: #000000;
      resize: none;

      &:focus {
         background: #ffffff;
         border: 0.5px solid #2979ff;
      }
   }
}