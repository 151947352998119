.search_input_wrapper {
  position: relative;

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18rem;
  }
}

.search_field {
  width: 19.5rem;
  height: 2rem;
  padding: 0.5rem 1rem;
  background-color: #fafafa;
  border: 0.5px solid #f5f5f5;
  outline: none;
  border-radius: 1.5rem;
  
  &:focus {
    border: 0.5px solid #2979FF;
  }
}