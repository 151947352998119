.item-info_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  margin-left: 64px;
  width: 62%;
}

.item-info_row {
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  gap: 48px;
}

.item-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  padding: 4px 0;
}

.item-info_label {
  color: #737373;
  font-size: 13px;
  width: 52px;
}

.item-info_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item-info_item {
  font-size: 13px;
  margin-bottom: 10px;
}