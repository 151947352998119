.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 132px 40px;
  padding-top: 60px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
