$position_absolute: absolute;
$icon_hover: brightness(0) saturate(100%) invert(35%) sepia(38%) saturate(893%) hue-rotate(179deg) brightness(98%) contrast(90%);
$icon_active: brightness(0) saturate(100%) invert(16%) sepia(97%) saturate(1014%) hue-rotate(185deg) brightness(92%) contrast(97%);
$title_hover: #3d67ac;
$title_active: #0b2e69;

.font_style {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  color: #ffffff;
}

.menu_pm_wrapper {
  width: 5.625rem;
  height: 100vh;
  background-color: rgba(11, 46, 105, 1);
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-out;
  padding-top: 1.26rem;
  z-index: 100;
  box-sizing: border-box;

  @media screen and (max-width: 1280px) {
    margin-left: -5.625rem;
  }

  a {
    text-decoration: none;
  }

  .menu_pm_logo {
    display: flex;
    flex-direction: row;
    gap: 2px;
    margin-left: 2rem;

    .binariks_title_icon {
      animation: fadeOut;
      animation-duration: 0;
      animation-fill-mode: forwards;
    }

    .black_cap {
      position: absolute;
      top: 2px;
      left: 14px;
      width: 48px;
      height: 48px;

      img {
        max-width: 100%;
      }
    }
  }

  .menu_pm_footer {
    display: none;
  }

  .icon_pm_container,
  .icon_pm_container_active {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0.81rem;
    width: 3.125rem;
    box-sizing: border-box;

    .icon_pm_type {
      width: 1.875rem;
    }
  }

  .icon_pm_container_active {
    background: #3d67ac;
    border-radius: 5px;

    .icon_pm_type {
      filter: $icon_active;
    }

    .icon_pm_title {
      @extend .font_style;
      color: $title_active;
    }
  }

  .icon_pm_container:hover {
    filter: $icon_hover;
  }

  .icon_pm_title {
    display: none;
    animation: fadeOut;
    animation-duration: 0;
    animation-fill-mode: forwards;
  }

  &__extended {
    @extend .menu_pm_wrapper;
    width: 11.94rem;
    transition: all 0.2s ease-out;

    @media screen and (max-width: 1280px) {
      margin-left: 0;
    }

    .menu_pm_logo>.binariks_title_icon {
      animation: fadeIn;
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
      transition: all 0.2s ease-out;
    }

    .icon_pm_container,
    .icon_pm_container_active {
      width: 9.44rem;
      transition: all 0.2s ease-out;
    }

    .icon_pm_title {
      display: block;
      animation: fadeIn;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      transition: all 0.3s ease-out;
    }

    .menu_pm_footer {
      position: absolute;
      bottom: 1rem;
      width: 100%;
      padding-right: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      box-sizing: border-box;
      animation: fadeIn;
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
      transition: all 0.2s ease-out;

      &:first-child {
        cursor: pointer;
      }
    }
  }
}

.menu_pm_container {
  position: relative;
  width: 100%;
  height: fit-content;
  top: 2.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 0 1.25rem;
}

.menu_pm_arrow {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1280px) {
    position: fixed;
    left: 0;
    background: rgba(41, 121, 255, 0.22);
    border-radius: 0 6px 6px 0;
    width: 16px;
    height: 34px;
  }

  .icon_pm_type {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    color: #ffffff;

    &:hover {
      border-radius: 3px;
      background: transparent;

      & .iconSvg path {
        fill: #ffffff;
      }
    }
  }
}

.icon_pm_type {
  cursor: pointer;
}

.icon_pm_title {
  @extend .font_style;
}

@keyframes fadeIn {

  0%,
  60% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
  }

  100% {
    visibility: hidden;
  }
}