.container {
  margin-top: -60px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-width: 1280px;
}

.ml100 {
  margin-left: 100px;
}