@import "./styled-tables/pm-customers-list.scss";
@import "./styled-tables/pm-employee-list.scss";
@import "./styled-tables/pm-project-employee-list.scss";
@import "./styled-tables/pm-projects-list.scss";
@import "./styled-tables/pm-reports-list.scss";
@import "./styled-tables/pm-tasks-list.scss";
@import "./styled-tables/pm-team-list.scss";
@import "./styled-tables/sickness-list.scss";
@import "./styled-tables/time-tracking.scss";
@import "./styled-tables/total-reports-list.scss";
@import "./styled-tables/vacations-list.scss";

/*************Scrollbar styles***************/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  height: 54px;
  background-color: #9fa6ae;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  height: 100%;
  background-color: #d3d4d6;
}

/****Default Table Styles*****/
.table-wrapper {
  display: block;
  width: 100%;
  max-width: inherit;
  height: 100%;
  max-height: inherit;
  overflow: auto;

  & table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
  }
}

.scroll {

  & th,
  td {
    margin: 10px 20px;
  }
}

.table_pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem 0;
  font-size: 12px;
  color: #737373;
}

.expand_all_container {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  position: relative;
}

.expand_all_label {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #737373;
  width: max-content;
  left: 3rem;
}

.expand_all_label_container {
  display: flex;
  align-items: center;
}