@import '../../../styles/bcoin-animations.scss';

.bcoin-items-list-container {
  height: calc(100% - 374px);
  position: absolute;
  top: 0;
  padding-top: 324px;
  left: 70px;
  right: 70px;
  overflow: scroll;
  animation-name: fadeInBlock;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  z-index: 10;

  &::-webkit-scrollbar {
    display: none;
  }
}

.bcoin-items-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 132px 40px;
  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.bcoin-items-list-end {
  display: flex;
  margin-top: 36px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;

  .bcoin-items-list-end-txt {
    font-family: 'IBM Plex Mono', monospace;
    font-style: normal;
    font-weight: 200;
    font-size: 28px;
    line-height: 48px;
    text-align: center;
    color: #999999;
  }

  .bcoin-items-list-end-img {
    width: 248px;

    img {
      max-width: 100%;
    }
  }
}

.bcoin-item-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  position: relative;
  min-height: 316px;
  padding: 156px 24px 24px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid transparent;
  border-image: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.51),
      rgba(255, 255, 255, 0.07)
    )
    1;
  z-index: 10;

  @media screen and (max-width: 1660px) {
    min-width: 33%;
    padding: 156px 16px 16px;
  }

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-image: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.2)
      )
      1;
    .item-card-img {
      top: -135px;
      transition: all 0.3s ease-in-out;
    }
  }

  .card-tooltip {
    position: absolute;
    border: 1px solid transparent;
    border-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.03)
      )
      1;
    font-family:
      IBM Plex Mono,
      monospace;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;

    span {
      font-weight: 600;
    }
  }
}

.item-card-img {
  position: absolute;
  top: -120px;
  z-index: -1;
  animation-name: fadeInBlock;
  user-select: none;
  transition: all 0.3s ease-in-out;

  img {
    max-width: 100%;
  }
}

.item-card-title {
  font-family: IBM Plex Mono;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #ffffff;
  pointer-events: visible;
  cursor: pointer;

  @media screen and (max-width: 1660px) {
    line-break: auto;
    font-size: 16px;
    line-height: 28px;
  }
}

.item-card-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
}

.item-card-price-value {
  font-family:
    Space Grotesk,
    sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 2px;
  color: #2979ff;
}

.item-card-price-currency {
  font-family:
    IBM Plex Mono,
    monospace;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #ffffff;
}

.item-currency-value {
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #ffffff;
}

.item-card-wishlist-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #343538;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #404245;
    transition: all 0.2s ease-in-out;
  }
}

.item-card-progress-bar-container {
  position: absolute;
  bottom: -5px;
  left: -1px;
  width: calc(100% + 2px);
  height: 4px;
  background: rgba(255, 255, 255, 0.12);

  .item-card-progress-bar {
    height: 100%;
    background: linear-gradient(90deg, #0052da 0%, #3399ff 100%);
  }
}

.bcoin-item-card:hover .item-card-go-to-item {
  background-color: #2979ff;
}

.pointer-right {
  position: absolute;
  right: -32px;
  bottom: 92px;
  transform: rotate(90deg);
  animation-name: fadeInBlock;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;

  img {
    max-width: 116px;
  }
}

.item-card-badge {
  position: absolute;
  border-radius: 0;
  z-index: 13;
  top: 0;
  right: 0;
  height: 54px;
  width: 54px;
  pointer-events: visible;

  &-content {
    font-family: IBM Plex Mono;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
}
