.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 46, 105, 0.31);
}

.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  min-height: 100px;
  min-width: 570px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 73px rgba(32, 32, 35, 0.2);
  box-sizing: border-box;
  width: 728px;
  padding: 0 20px;

  @media screen and (min-width: 1025px) {
    min-width: 50%;
  }

  @media screen and (max-width: 920px) {
    width: 90%;
  }
}

.modal_header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 25px;
  }

  &.closeRight {
    justify-content: end;
    margin: 0;
  }
}

.modal_header_title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  white-space: nowrap;
}

.modal_body {
  position: relative;
  height: fit-content;
  padding: 1.25rem 0;

  @media screen and (max-width: 1024px) {
    height: 80%;
  }
}

.modal_footer {
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0 0 1rem 0;
}

.modal_form {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  &_fields {
    width: 272px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_date_type {
      display: flex;
      gap: 10px;
      align-items: flex-end;
    }

    &_time {
      display: flex;
      gap: 10px;
      align-items: flex-end;
      width: 180px;
    }
  }

  &_text_area {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_field {
      box-sizing: border-box;
      outline: none;
      border: 1px solid #dee3ee;
      border-radius: 2px;
      padding: 0.5rem;
      resize: none;
      width: 100%;
      height: 224px;

      &_title {
        font-size: 12px;
        color: #878787;
        line-height: 14px;
      }
    }
  }

  &_submit {
    align-self: flex-end;
    padding: 10px 40px;
  }

  &_select_wrapper {
    gap: 0.35rem;
    flex: 1;
  }
}
