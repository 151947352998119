.styledCalendar {
  .react-datepicker {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #ffffff;
    border: none;
    width: 100%;

    &__month-container {
      background: #ffffff;
      border: none;
    }

    &__header {
      background-color: #ffffff;
      border-bottom: none;
    }

    &__current-month {
      font-size: 1.2rem;
    }

    &__week {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__day-names {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0.625rem;
      margin-top: 1.25rem;
    }

    &__day-name {
      font-weight: 500;
      font-size: 0.875rem;
    }

    &__day {
      font-size: 0.875rem;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      &--today,
      &--weekend {
        background: transparent;
        border: none;
      }

      &--today {
        font-weight: 400;
        color: #2979ff;
      }

      &--weekend {
        color: #cad0dc;
      }

      &--highlighted {
        background: transparent;
        border: none;
        color: #ff0000;
      }

      &--selected,
      &--in-range {
        border-radius: 50% !important;
        background: #f3f7ff !important;
        border: 1px solid #ccdfff !important;
        color: #2979ff !important;
      }

      &--in-selecting-range {
        border-radius: 50% !important;
        background: #f3f7ff !important;
        color: #2979ff !important;
      }

      &--outside-month {
        color: #cad0dc;
        pointer-events: none;
      }

      &:hover {
        border-radius: 50%;
        background: #f3f7ff;
        color: #2979ff;
      }
    }
  }

  .current_day {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.styledCalendarDisabled {
  @extend .styledCalendar;
  pointer-events: none;
}

.highlight_selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
  background: #f3f7ff;
  border: 1px solid #ccdfff;
  color: #2979ff;
}
