.time-tracking-list {
    box-sizing: content-box;
    width: 100%;
    border-collapse: collapse;


    .edit  {
        background: #FFFAF1;
    }

    thead {
        tr {
            color: #0B2E69;
            font-style: normal;
            font-weight: 500;
            font-size: .925rem;

            th {
                padding: 1rem;
                border-bottom: 2px solid rgba(204, 214, 235, 1);
                text-align: start;

                &:first-of-type,
                &:nth-of-type(2) {
                    width: 20%;
                }

                &:nth-of-type(3) {
                    width: 10%;
                    text-align: center;
                }

                &:nth-of-type(4) {
                    width: 32%;
                    text-align: center;
                }

                &:last-of-type {
                    width: 18%;
                    text-align: end;
                }
            }
        }
    }

    tbody {
        font-size: .9rem;

        tr {
            // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
            background: #FAFDFF;
            
            td {
                padding: 1rem;
                text-align: start;
                border-bottom: 5px solid white;

                &:first-of-type,
                &:nth-of-type(2) {
                    width: 20%;
                }

                &:nth-of-type(3) {
                    width: 10%;
                    text-align: center;
                }
            }
        }

        &:before {
            line-height:2em;
            content:".";
            color:transparent;
            display:block;
        }
    }
}

.table_btn_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: .5rem;
}

.table_btn_none {
    display: none;
}