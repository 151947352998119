.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(11, 46, 105, 0.31);
}

.modal_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    min-height: 100px;
    min-width: 570px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 73px rgba(32, 32, 35, 0.2);
    width: fit-content;
    height: fit-content;
    padding: 0 1.6875rem;

    @media screen and (min-width: 1025px) {
        min-width: 50%;
    }

    @media screen and (max-width: 920px) {
        width: 90%;
    }
}

.modal_header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 0 0;
    width: 100%;

    &>*:not(:last-child) {
        margin-right: 25px;
    }

    &.closeRight {
        justify-content: end;
        margin: 0;
    }
}

.modal_header_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    white-space: nowrap;
}

.modal_body {
    position: relative;
    height: fit-content;
    border: 1px solid #ced8ec;
    border-radius: 2px;
    padding: 1.25rem;

    @media screen and (max-width: 1024px) {
        height: 80%;
    }
}

.modal_footer {
    margin-top: 20px;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    padding: 0 0 1rem 0;

    &>*:not(:last-child) {
        margin-right: 40px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    justify-content: center;
}

.form_col {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
        gap: 1.25rem;
    }
}

.modal_main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
    padding: 0;

    & label {
        margin-left: 0;
    }
}

.report_data,
.report_description {
    @extend .modal_main;
}

.report_data {
    height: 1.75rem;
}

.report_description {
    width: 100%;
    height: 5.25rem;
    align-items: flex-start;
}

.report_task,
.report_duration,
.report_description_input {
    box-sizing: border-box;
    font-size: .875rem;
    background: rgba(140, 159, 195, 0.17);
    border: 0.3px solid rgba(140, 159, 195, 0.29);
    border-radius: 2px;
    outline: none;
}

.report_description_input {
    width: 100%;
    height: 100%;
    resize: none;
    padding: .5rem;
}

.data_input,
.data_input_readonly,
.data_textarea_readonly {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    width: 100%;
    height: 100%;
}

.data_input {
    width: 100%;
    height: 100%;
}

.data_input_readonly {
    color: #171717;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.data_textarea_readonly {
    color: #171717;
    display: flex;
    text-overflow: clip;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
}