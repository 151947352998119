.tooltip {
  position: absolute;
  transform: translateY(-100%) translateX(-100%);
  z-index: 9999;
  background-color: #ffffff;
  min-height: 200px;
  min-width: 300px;
  height: 50vh;
  width: auto;
  border-radius: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    right: 10%;
    transform: rotate(45deg);
    background-color: white;
    width: 10px;
    height: 10px;
  }

  img {
    width: auto;
    max-height: 100%;
  }
}
