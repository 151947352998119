@import '../../../styles/bcoin-animations.scss';

.bcoin-body {
  display: grid;
  grid-template-columns: 5fr 7fr;
  height: calc(100vh - 233px);
  overflow: hidden;
  background-color: rgba(0, 0, 0);
  padding-top: 131px;
}

.bcoin-body-left-col {
  box-sizing: border-box;
  padding: 88px 108px 0 108px;
  height: calc(100vh - 233px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  backdrop-filter: blur(50px);

  .gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(
      0deg,
      rgb(2, 2, 2) 0%,
      rgba(3, 3, 3, 0.8) 20%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

@mixin imgProperties {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  animation-name: fadeInBlock;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@mixin offsetImgProperties {
  position: absolute;
  left: 60%;
  @include imgProperties;
}

.bcoin-body-left-col-img {
  margin-top: 48px;
  @include imgProperties;

  img {
    width: 240px;
  }
}

.bcoin-body-left-col-img-xl {
  margin-top: 48px;
  @include imgProperties;

  img {
    width: 454px;
  }
}

.bcoin-body-left-col-img-offset {
  @include offsetImgProperties;
  bottom: 16px + 80px;

  @media screen and (max-width: 1660px) {
    left: 70%;
  }

  img {
    width: 400px;

    @media screen and (max-width: 1660px) {
      width: 300px;
    }
  }
}

.balance-image {
  bottom: 0;
  left: auto;
  right: -140px;

  @media screen and (max-width: 1660px) {
    right: -70px;
  }
}

.faq-image {
  bottom: -40px;
}

.how-to-get-image {
  bottom: -0;
  left: auto;
  right: -60px;
}

.bcoin-body-left-col-img-xl-offset {
  @include offsetImgProperties;
  bottom: -32px + 80px;

  img {
    max-width: 420px;
  }
}

.bcoin-body-right-col {
  z-index: 0;
  height: calc(100vh - 80px);
  min-width: 100%;
  position: relative;

  .bcoin-rewards-header {
    display: flex;
    width: 100%;
    position: absolute;
    justify-content: space-between;
    padding: 16px 72px;
    background-color: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(20px);
    z-index: 200;
    top: 100px;
    box-sizing: border-box;

    .inputWithIcon {
      position: relative;
      display: flex;
      align-items: center;

      .icon {
        position: absolute;
        left: 16px;
        cursor: pointer;
        fill: rgba(255, 255, 255, 0.5);
        transition: fill 0.3s ease;
      }

      input {
        width: 350px;
        padding: 8px 48px;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-image: linear-gradient(
            330deg,
            rgba(255, 255, 255, 0.07),
            rgba(255, 255, 255, 0.31)
          )
          1;
        background-color: transparent;
        font-family:
          IBM Plex Mono,
          monospace;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: rgb(255, 255, 255, 0.8);
        outline: none;

        &::placeholder {
          font-family:
            IBM Plex Mono,
            monospace;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px transparent inset;
          -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
          transition: background-color 5000s ease-in-out 0s;
        }
      }
    }

    .bcoin-rewards-filter-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border: 1px solid transparent;
      border-image: linear-gradient(
          330deg,
          rgba(255, 255, 255, 0.07),
          rgba(255, 255, 255, 0.31)
        )
        1;
      background-color: transparent;
      cursor: pointer;

      .separator {
        width: 1px;
        height: 20px;
        background-color: rgba(255, 255, 255, 0.3);
      }

      span {
        display: flex;
        justify-content: center;
        flex: 1;
        padding: 8px 16px;
        font-family: IBM Plex Mono;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        white-space: nowrap;
        transition: all 0.3s ease-in-out;

        &.active {
          background-color: #ffffff52;
          color: #ffffff;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

.bcoin-body-right-col,
.bcoin-body-right-col-bg {
  top: -100px;
  padding-top: 60px;
  background-color: #181a1d;
  position: relative;

  @media screen and (max-width: 1536px) {
    padding-top: 0;
  }
}

.bcoin-body-right-col-bg {
  height: 100%;
  background-image: url('../../../images/VectorBackground.png');
}

.right-col-animated {
  animation-name: fadeInByLeft;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.bcoin-icon-wrapper {
  position: relative;
  display: flex;
  height: calc(100vh - 132px);
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1660px) {
    height: 100vh;
  }

  img {
    width: 600px;

    @media screen and (max-width: 1660px) {
      width: 520px;
    }
  }
}

.bcoin-body-title,
.bcoin-body-subtitle {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-size: 84px;
  line-height: 100px;
  font-weight: 700;
  letter-spacing: 0px;

  @media screen and (max-width: 1660px) {
    font-size: 76px;
    line-height: 92px;
  }
}

.bcoin-what-is-it-title,
.bcoin-what-is-it-subtitle {
  font-size: 96px;
  line-height: 120px;
  font-weight: 700;

  @media screen and (max-width: 1536px) {
    font-size: 84px;
    line-height: 96px;
  }

  @media screen and (max-width: 1660px) {
    font-size: 92px;
    line-height: 100px;
  }
}

.bcoin-body-title {
  color: #ffffff;
}

.bcoin-body-subtitle {
  color: #2979ff;
}

.bcoin-body-description {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 32px;
  white-space: pre-wrap;
  max-width: 90%;

  @media screen and (max-width: 1536px) {
    font-size: 24px;
    line-height: 36px;
  }
}

.bcoin-body-info {
  font-family: 'IBM Plex Mono', monospace;
  color: rgba(255, 255, 255, 0.2);
  font-style: normal;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0px;
  margin-top: 32px;
  padding-right: 68px;
  white-space: pre-wrap;
}

.bcoin-body-kudos {
  padding-top: 100px;
}

.bcoin-how-it-works {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  animation-name: fadeInByLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.bcoin-how-it-works-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: calc((100vh - 233px) / 3);
}

.bcoin-how-it-works-right-col {
  padding-left: 120px;
  padding-top: 100px;
}

.bcoin-how-it-works-key {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 57px;
  letter-spacing: 0.6px;
  color: #ffffff;
  width: 88px;
  padding: 32px;
}

.bcoin-how-it-works-image {
  margin-top: 20px;
  img {
    @media screen and (max-width: 1660px) {
      width: 340px;
    }
  }
}

.bcoin-how-it-works-value {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  padding: 32px;
}

.bcoin-how-it-works-divider {
  position: absolute;
  width: 1px;
  background-color: #999999;
  top: 0;
  left: 97px;
  bottom: 0;
}

.bcoin-how-it-work-line1 {
  position: absolute;
  height: 1px;
  background-color: #999999;
  top: calc((100vh - 233px) / 3);
  left: 97px;
  right: 0;
}

.bcoin-how-it-work-line2 {
  position: absolute;
  height: 1px;
  background-color: #999999;
  top: calc((100vh - 233px) / 3 * 2);
  left: 97px;
  right: 0;
}

.bcoin-how-it-works-dot {
  position: absolute;
  width: 12px;
  height: 12px;
  background: #1a69ff;
  left: 92px;
}

.bcoin-body-what-to-get {
  padding-top: 0 !important;
  position: relative;
}

.bcoin-what-to-get-left-col {
  overflow: hidden;
}

.bcoin-kudos {
  position: relative;
  top: 60px;
  bottom: 24px;
  width: 70%;
  height: calc(100vh - 290px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: fadeInByLeftAndRight;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.bcoin-kudos-description {
  font-size: 24px;
  line-height: 40px;
}

.bcoin-kudos-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.bcoin-kudos-bordered-item {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.44);
  padding: 14px 32px;
  position: relative;
  height: 72px;
}

.bcoin-kudos-item {
  padding: 14px 24px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bcoin-kudos-item-txt {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #999999;

  a {
    color: inherit;
  }
}

.bcoin-kudos-step {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.bcoin-kudos-action {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  color: #ffffff;
}

.bcoin-kudos-divider {
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  left: calc(50% - 1px);
  background-color: #999999;
}

.bcoin-kudos-counter {
  position: absolute;
  right: -21px;
  top: -1px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a69ff;
  font-family: 'Space Mono', monospace;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  z-index: 10;
}

.bcoin-what-to-get-title,
.bcoin-balance-title {
  color: #2979ff;
}

.bcoin-what-to-get-subtitle,
.bcoin-balance-subtitle {
  color: #ffffff;
}

.bcoin-body-rich-text {
  margin-top: 32px;
  color: #ffffff;
  opacity: 80%;
  overflow-y: scroll;
  cursor: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  h4 {
    font-family: 'IBM Plex Mono', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
  }

  p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    margin: 26px 0;

    b {
      font-weight: 500;
    }
  }
}
