.spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(51 51 51 / 10%);
    z-index: 998;
}

.lds-grid {
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;

    div {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid white;
        background: #2979FF;
        animation: lds-grid 1s linear infinite;

        &:nth-child(1) {
            top: 8px;
            left: 8px;
            animation-delay: -0.4s;
        }

        &:nth-child(2) {
            top: 8px;
            left: 32px;
            animation-delay: -0.2s;
        }

        &:nth-child(3) {
            top: 8px;
            left: 56px;
            animation-delay: 0s;
        }

        &:nth-child(4) {
            top: 32px;
            left: 8px;
            animation-delay: 1s;
        }

        &:nth-child(5) {
            top: 32px;
            left: 32px;
            animation: none;
        }

        &:nth-child(6) {
            top: 32px;
            left: 56px;
            animation-delay: 0.2s;
        }

        &:nth-child(7) {
            top: 56px;
            left: 8px;
            animation-delay: 0.8s;
        }

        &:nth-child(8) {
            top: 56px;
            left: 32px;
            animation-delay: 0.6s;
        }

        &:nth-child(9) {
            top: 56px;
            left: 56px;
            animation-delay: 0.4s;
        }
    }
}

@keyframes lds-grid {
    100% {
        opacity: 1;
        background: #0b2e69;
    }

    50% {
        background: #FFFFFF;
    }
}