.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 46, 105, 0.31);
}

.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  min-height: 100px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 73px rgba(32, 32, 35, 0.2);
  width: fit-content;
  height: fit-content;
  padding: 0 1.6875rem;

  p {
    padding: 0 10px;
    width: 194px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: fit-content;
    max-height: 100%;
  }
}

.modal_header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;

  &>*:not(:last-child) {
    margin-right: 25px;
  }

  &.closeRight {
    justify-content: end;
    margin: 0;
  }
}

.modal_footer {
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0 0 1rem 0;

  &>*:not(:last-child) {
    margin-right: 40px;
  }
}