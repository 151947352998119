.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  h2 {
    text-align: center;
    font-family:
      IBM Plex Mono,
      monospace;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    color: #ffffff;
    width: 270px;
  }

  p {
    font-family: IBM Plex Mono, monospace;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 24px;
    color: #ffffff;
    text-align: center;
    width: 300px;

    span {
      font-weight: 700;
    }
  }

  .buttons {
    display: flex;
    gap: 32px;
    margin-top: 40px;
    width: 100%;

    .cancelBtn,
    .yesBtn {
      padding: 16px 32px;
      box-sizing: border-box;
      border: none;
      font-family: IBM Plex Mono, monospace;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      cursor: pointer;
      transition: 0.3s;

      &.cancelBtn {
        background: rgba(255, 255, 255, 0.05);
        color: white;
        border: 1px solid transparent;
        border-image: linear-gradient(
            330deg,
            rgba(255, 255, 255, 0.07),
            rgba(255, 255, 255, 0.51)
          )
          1;

        &:hover {
          background: #444;
        }
      }

      &.yesBtn {
        background: #007bff;
        color: white;
        flex-grow: 1;

        &:hover {
          box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
          transition: all 0.2s ease-out;
        }
      }
    }
  }
}
