.layout {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  animation-name: fadeInBlock;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1b1c1e;
  width: 540px;
  min-height: 570px;
  box-sizing: border-box;
  box-shadow: 0px 0px 73px rgba(32, 32, 35, 0.2);
  padding: 64px;
  border: 1px solid transparent; /* Initial border setup */
  border-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.51),
    rgba(255, 255, 255, 0.07)
  ) 1;

  .mainInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .circle {
      display: flex;
      box-sizing: border-box;
      width: 127px;
      height: 127px;
      border: 5px solid #2979ff;
      border-radius: 100%;
      position: relative;

      &.inactive {
        border: none;
        align-items: center;
        justify-content: center;
        width: 127px;
        height: 127px;
        background: linear-gradient(to bottom right, #f3f3f3e5 0%, #f0f0f017 70%);

        &::after {
          content: '';
          display: flex;
          width: 117px;
          height: 117px;
          background: #1d1f23;
          border-radius: 100%;
        }
      }

      img {
        position: absolute;
        top: -48px;
        left: -28px;
        width: 146px;
        height: 146px;
        object-fit: contain;

        &.inactive {
          filter: blur(7px) grayscale(100%);
        }
      }

      .charityCount {
        position: absolute;
        bottom: -12px;
        right: -12px;
        background: #2979ff;
        color: #ffffff;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        font-family: 'IBM Plex Mono', monospace;
      }
    }

    .name {
      font-family: 'IBM Plex Mono', monospace;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 56px;
      color: #ffffff;
      margin-top: 24px;
      text-align: center;

      .count {
        margin-left: 6px;
        opacity: 0.7;
      }
    }

    .description {
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 300;
      font-size: 24px;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      opacity: 0.8;
      margin-top: 24px;
    }
  }

  .rewardInfo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 24px;

    .rewardTitle {
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      opacity: 0.8;
      text-transform: uppercase;
    }

    .rewardValue {
      display: flex;
      align-items: baseline;
      gap: 6px;
      font-family: 'Space Grotesk', sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 27px;
      color: #2979ff;

      span {
        font-family: 'IBM Plex Mono', monospace;
        font-size: 16px;
        line-height: 12px;
        font-weight: 300;
        color: #ffffff;
        opacity: 0.8;
      }
    }
  }
}
