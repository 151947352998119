.root {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  row-gap: 40px;
  column-gap: 32px;

  .achievementItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 165px;
    gap: 16px;
    width: auto;
    padding-bottom: 37px;
    cursor: pointer;

    .circle {
      display: flex;
      box-sizing: border-box;
      width: 95px;
      height: 95px;
      border: 4px solid #2979ff;
      border-radius: 100%;
      position: relative;

      .charityCount {
        position: absolute;
        bottom: -12px;
        right: -12px;
        background: #2979ff;
        color: #ffffff;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        font-family: 'IBM Plex Mono', monospace;
      }

      &.inactive {
        border: none;
        align-items: center;
        justify-content: center;
        width: 95px;
        height: 95px;
        background: linear-gradient(
          to bottom right,
          #f3f3f3e5 0%,
          #f0f0f017 70%
        );

        &::before {
          content: '';
          display: flex;
          width: 87px;
          height: 87px;
          background: #1d1f23;
          border-radius: 100%;
        }
      }

      img {
        position: absolute;
        top: -30px;
        left: -20px;
        width: 109px;
        height: 109px;
        object-fit: contain;
        user-select: none;
        pointer-events: none;

        &.inactive {
          filter: blur(7px) grayscale(100%);
        }
      }
    }

    .name {
      font-family: 'IBM Plex Mono', monospace;
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      color: #ffffff;

      .count {
        margin-left: 6px;
        opacity: 0.7;
      }
    }
  }

  .achievementItem:hover {
    transform: translate(0, -15px);
    transition: all 0.3s ease-in-out;
  }

  .achievementItem:not(:hover) {
    transition: transform 0.3s ease-in-out;
  }
}
