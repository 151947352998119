.container {
    background-color: #000000;
    height: 100vh;
    // margin-left: 5.625rem;
    overflow-x: hidden;
    min-width: calc(1440px - 5.625rem);

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 1280px) {
        margin-left: 0;
    }
}

.tabContainer {
    position: absolute;
    bottom: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 102px;
    width: 100%;
    z-index: 20;

    @media screen and (max-width: 1280px) {
        width: 100vw;
    }
}