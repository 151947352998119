@import '../../../styles/bcoin-animations.scss';

.bcoin-faq-container {
  height: calc(100% - 120px);
  width: calc(100% - 144px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 64px;
  top: 160px;
  padding-right: 24px;
  position: relative;
  overflow: scroll;
  animation-name: fadeInByLeftAndRight;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  margin-top: -60px;
  padding-top: 60px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.bcoin-faq-right-col {
  padding-top: 0 !important;
}

.bcoin-faq-column {
  display: flex;
  flex-direction: column;
  gap: 64px;
  z-index: 1;
  height: 100%;
  padding-bottom: 60px;
}

.bcoin-faq-tile {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 24px 32px;
  gap: 18px;
  height: fit-content;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid transparent; /* Initial border setup */
  border-image: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.51),
      rgba(255, 255, 255, 0.07)
    )
    1;
}

.bcoin-faq-tile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bcoin-faq-tile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  flex: 0;
  padding: 0 12px;
  text-align: center;
  background-color: #343538;
  backdrop-filter: blur(5px);
  cursor: pointer;

  &:hover {
    svg path {
      stroke: #2979ff;
    }
  }
}

.bcoin-faq-question {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.answer-txt,
.answer-txt-list {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.answer-txt-list {
  padding-left: 12px;
}
