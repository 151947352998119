.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 46, 105, 0.31);
}

.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  min-height: 100px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 73px rgba(32, 32, 35, 0.2);
  width: fit-content;
  padding: 0 1.6875rem;

  @media screen and (max-width: 820px) {
    max-height: 100%;
  }
}

.modal_header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0 0;

  &>*:not(:last-child) {
    margin-right: 25px;
  }

  &.closeRight {
    justify-content: end;
    margin: 0;
  }
}

.modal_header_title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.modal_body {
  position: relative;
  height: 23rem;
  border: 1px solid #ced8ec;
  border-radius: 2px;
  padding: 1.25rem;
  overflow: auto;

  @media screen and (max-width: 820px) {
    height: 80%;
  }
}

.modal_footer {
  margin-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding: 0 0 1rem 0;

  &>*:not(:last-child) {
    margin-right: 40px;
  }
}

.modal_form {
  width: 700px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 820px) {
    width: 350px;
  }
}

.title_wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
  padding: 15px 0;

  button {
    margin-left: 10px;
  }
}

.customer_block {
  display: flex;
  padding: 0 150px 20px 0;
}

.common_input {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 175px;
    height: 23px;
    background: rgba(140, 159, 195, 0.17);
    border: 0.3px solid rgba(140, 159, 195, 0.29);
    border-radius: 2px;
    outline: none;
    padding: 0 8px 0;
  }

  &>span,
  .common_input_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #737373;
    width: 80px;
    margin: 0 15px 0 0;
    white-space: nowrap;
  }

  @media screen and (max-width: 820px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.common_input_reverse {
  @extend .common_input;
  flex-direction: row-reverse;

  @media screen and (max-width: 820px) {
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.cp_data {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  label+label {
    margin: 15px 0 0 0;
  }
}

.cp_item {
  width: 100%;
  display: flex;
  margin: 16px 0;
  gap: 100px;

  &+& {
    margin-top: 30px;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
    gap: 1.25rem;
  }
}

.cp_email {
  display: flex;
  align-items: center;

  & button {
    margin-left: 15px;
    transform: translate(-50%, 0);

    &:hover {
      transform: translate(-50%, 0) scale(1.15);
    }
  }
}

.cp_emails {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
}