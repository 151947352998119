.heading a {
    text-decoration: none;
}

.containerMinWidth {
    min-width: 767px;
}

.container {
    margin-left: 5.625rem;
    padding: 2rem 2rem 2rem calc(2rem + 10px);
    animation-name: fadeIn;
    animation-duration: .6s;
    animation-fill-mode: forwards;
    transition: all .6s ease;

    @media screen and (max-width: 1280px) {
        margin-left: 0;
        padding: 1rem;
    }

    table {
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 42px;
            top: 0;
            bottom: 0;
            background-color: rgb(250, 250, 250);
        }

        &::before {
            left: -42px;
        }

        &::after {
            right: -42px;
        }
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 2rem 2rem calc(2rem + 10px);
    margin-left: 5.625rem;

    @media screen and (max-width: 1280px) {
        margin-left: 0;
    }

    @media screen and (max-width: 920px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2rem;
        margin-bottom: 2rem;
    }

    a {
        color: unset
    }

    &:nth-of-type(3) {
        padding: 0 2rem 2rem calc(2rem + 10px);
    }

    .toggle-slider-block {
        padding-top: 10px;
    }
}

.switchingWrapper {
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 46px;

    .toggle-slider-block {
        flex-direction: row-reverse;
    }

    @media screen and (max-width: 920px) {
        position: relative;
        padding-left: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
}

.headerInfo {
    justify-content: flex-start;
    align-items: flex-start;

    button {
        margin-left: auto;
    }
}

.report {
    @extend .container;
    background-color: rgba(250, 250, 250, 1);
    padding-top: 1rem;

    &:empty {
        background-color: #ffffff;
    }
}

.noItems {
    animation-name: fadeIn;
    animation-duration: .6s;
    animation-fill-mode: forwards;
    transition: all .6s ease;
}

.toolTipTxt {
    width: 4rem;
    height: fit-content;
    text-align: start;
}

.toolTip {
  z-index: 1;
  background-color: #3d67ac;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
