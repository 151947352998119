.file-input {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: .5rem;
    }

    &__input--wrapper {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
    }

    &__input {
        opacity: 0;
        left: 0;
        top: -1rem;
        width: 4.5rem;
        height: 3rem;
        position: absolute;
        cursor: pointer;
    }

    &__warning {
        font-size: .75rem;
        color: #737373;
    }

    &__txt {
        font-size: .75rem;
        color: #171717;
    }
}