.total-reports-list {
    box-sizing: content-box;
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            color: rgba(115, 115, 115, 1);
            font-style: normal;
            font-weight: 500;
            font-size: .925rem;
            background-color: #FFFFFF;

            th {
                padding: 1rem;
                border-bottom: 2px solid rgba(204, 214, 235, 1);

                &:first-of-type {
                    text-align: start;
                    width: 4%;
                }

                &:nth-of-type(2) {
                    text-align: start;
                    width: 32%;
                }

                &:nth-of-type(3) {
                    text-align: center;
                    width: 32%;
                }

                &:last-of-type {
                    text-align: end;
                    width: 32%;
                }
            }
        }
    }

    tbody {
        font-size: .9rem;

        .subRow-1,
        .subRow-2 {
            background: #FFFFFF;
        }

        .subRow-1 {
            &> :first-child {
                display: inline-block;
                margin-left: 12px;

                svg path {
                    fill: #737373;
                }
            }

            &> :nth-child(2) {
                padding-left: 24px;
                color: #171717;
                ;
            }

            &> :nth-child(3) {
                color: #171717;
                ;
            }
        }

        .subRow-2 {
            &> :nth-child(2) {
                padding-left: 32px;
            }
        }

        tr {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);

            &.subRow-0:nth-of-type(even) {
                background: #FAFAFA;
            }

            &.subRow-0:nth-of-type(odd) {
                background: #FAFDFF;
            }

            .editRow {
                background: #FFFAF1;
            }

            .editSubRow {
                background: rgba(255, 250, 241, .4);
            }

            td {
                padding: 1rem;
                font-family: 'Roboto';
                font-size: 14px;
                line-height: 18px;

                &:first-of-type {
                    text-align: start;
                    font-weight: 500;
                    width: 4%;
                }

                &:nth-of-type(2) {
                    text-align: start;
                    width: 32%;
                    font-weight: 600;
                    color: #0B2E69;
                }

                &:nth-of-type(3) {
                    text-align: center;
                    width: 32%;
                    font-weight: 400;
                    color: #171717;
                }

                &:last-of-type {
                    text-align: end;
                    width: 32%;
                    font-weight: 600;
                    color: #2579FF;

                }
            }
        }
    }
}

.notBillableHours {
    color: #2579FF;
}

.copyNameCell {
    display: flex;
    align-items: center;
    gap: 15px;
}