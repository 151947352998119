.checkbox_label {
  margin: 0.5rem 0 0.5rem 1rem;
  font-size: 14px;
  position: relative;
}

.default_checkbox,
.modal_default_checkbox {
  position: relative;
  margin-right: 0.5rem;
  margin-left: -1rem;
  visibility: hidden;

  &+.custom-indicator::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8062_31546)'%3E%3Cpath id='Vector' d='M14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0ZM14.2222 1.77778V14.2222H1.77778V1.77778H14.2222Z' fill='%23171717'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8062_31546'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    visibility: visible;
    position: absolute;
    cursor: pointer;
    transform: translateY(25%);
    left: 0;
  }

  &:checked {
    &+.custom-indicator::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8062_31539)'%3E%3Cpath id='Vector' d='M14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0ZM14.2222 1.77778V14.2222H1.77778V1.77778H14.2222ZM6.22222 12.4444L2.66667 8.88889L3.92 7.62667L6.22222 9.92889L12.08 4.07111L13.3333 5.33333' fill='%23171717'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8062_31539'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      visibility: visible;
      position: absolute;
      cursor: pointer;
      transform: translateY(25%);
      left: 0;
    }
  }

  &:disabled {
    &+.custom-indicator::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8062_31546)'%3E%3Cpath id='Vector' d='M14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0ZM14.2222 1.77778V14.2222H1.77778V1.77778H14.2222Z' fill='%23D3D3D3'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8062_31546'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      visibility: visible;
      position: absolute;
      transform: translateY(25%);
      left: 0;
    }

    &:checked {
      &+.custom-indicator::before {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8062_31539)'%3E%3Cpath id='Vector' d='M14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0ZM14.2222 1.77778V14.2222H1.77778V1.77778H14.2222ZM6.22222 12.4444L2.66667 8.88889L3.92 7.62667L6.22222 9.92889L12.08 4.07111L13.3333 5.33333' fill='%23D3D3D3'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8062_31539'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        visibility: visible;
        position: absolute;
        transform: translateY(25%);
        left: 0;
      }
    }
  }
}

.modal_default_checkbox {
  margin-right: 0;
  margin-left: 0;
}

.custom_checkbox {
  position: relative;
  margin-right: 0.5rem;
  margin-left: -1rem;
  visibility: hidden;

  &+.custom-indicator::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle id='Ellipse 2' cx='8' cy='8' r='7' stroke='%23171717' stroke-width='2'/%3E%3C/svg%3E%0A");
    visibility: visible;
    position: absolute;
    cursor: pointer;
    left: -18px;
  }

  &:checked {
    &+.custom-indicator::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle id='Ellipse 2' cx='8' cy='8' r='7' stroke='%23171717' stroke-width='2'/%3E%3Ccircle id='Ellipse 3' cx='8' cy='8' r='4' fill='%23171717'/%3E%3C/svg%3E%0A");
      visibility: visible;
      position: absolute;
      cursor: pointer;
      left: -18px;
    }
  }

  &:disabled {
    &+.custom-indicator::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle id='Ellipse 2' cx='8' cy='8' r='7' stroke='%23D3D3D3' stroke-width='2'/%3E%3C/svg%3E%0A");
      visibility: visible;
      position: absolute;
      left: -18px;
    }

    &:checked {
      &+.custom-indicator::before {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle id='Ellipse 2' cx='8' cy='8' r='7' stroke='%23D3D3D3' stroke-width='2'/%3E%3Ccircle id='Ellipse 3' cx='8' cy='8' r='4' fill='%23D3D3D3'/%3E%3C/svg%3E%0A");
        visibility: visible;
        position: absolute;
        left: -18px;
      }
    }
  }
}

.checkbox_reversed {
  position: relative;
  margin-left: -1rem;
  visibility: hidden;

  &+.custom-indicator::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8062_31546)'%3E%3Cpath id='Vector' d='M14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0ZM14.2222 1.77778V14.2222H1.77778V1.77778H14.2222Z' fill='%23171717'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8062_31546'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    visibility: visible;
    position: absolute;
    cursor: pointer;
    transform: translateY(-10%);
  }

  &:checked {
    &+.custom-indicator::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8062_31539)'%3E%3Cpath id='Vector' d='M14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0ZM14.2222 1.77778V14.2222H1.77778V1.77778H14.2222ZM6.22222 12.4444L2.66667 8.88889L3.92 7.62667L6.22222 9.92889L12.08 4.07111L13.3333 5.33333' fill='%23171717'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8062_31539'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      visibility: visible;
      position: absolute;
      cursor: pointer;
      transform: translateY(-10%);
    }
  }

  &:disabled {
    &+.custom-indicator::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8062_31546)'%3E%3Cpath id='Vector' d='M14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0ZM14.2222 1.77778V14.2222H1.77778V1.77778H14.2222Z' fill='%23D3D3D3'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8062_31546'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      visibility: visible;
      position: absolute;
      transform: translateY(-10%);
    }

    &:checked {
      &+.custom-indicator::before {
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8062_31539)'%3E%3Cpath id='Vector' d='M14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0ZM14.2222 1.77778V14.2222H1.77778V1.77778H14.2222ZM6.22222 12.4444L2.66667 8.88889L3.92 7.62667L6.22222 9.92889L12.08 4.07111L13.3333 5.33333' fill='%23D3D3D3'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8062_31539'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        visibility: visible;
        position: absolute;
        transform: translateY(-10%);
      }
    }
  }
}