@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&family=IBM+Plex+Sans&family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&family=IBM+Plex+Sans&family=Roboto:wght@500&family=Space+Grotesk:wght@700&display=swap");

@import "../src/components/Button/button.scss";
@import "../src/components/Checkbox/checkbox.scss";
@import "../src/components/ControlsPanel/controls-panel.scss";
@import "../src/components/FileInput/file-input.scss";
@import "../src/components/Loader/loader.scss";
@import "../src/components/MenuBar/menubar.scss";
@import "../src/components/ReportsFilterPanel/reports-filter-panel.scss";
@import "../src/components/SearchField/search-field.scss";
@import "../src/components/Select/select-container.scss";
@import "../src/components/TablePagination/table-pagination.scss";
@import "../src/components/Tables/tables.scss";
@import "../src/components/TextAreaField/text-area-field.scss";
@import "../src/components/ToggleSlider/toggle-slider.scss";
@import "../src/modules/BCoin/styles/bcoin-balance.scss";
@import "../src/modules/BCoin/styles/bcoin-body.scss";
@import "../src/modules/BCoin/styles/bcoin-faq.scss";
@import "../src/modules/BCoin/styles/bcoin-header.scss";
@import "../src/modules/BCoin/styles/bcoin-how-to-get.scss";
@import "../src/modules/BCoin/styles/bcoin-items.scss";
@import "../src/modules/BCoin/styles/bcoin-modal.scss";
@import "../src/modules/BCoin/styles/bcoin-tab.scss";

*,
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  scrollbar-gutter: auto;
}

@media screen and (min-width: 1280px) {
  body {
    overflow-x: hidden;
  }
}

.stop-scrolling {
  overflow-y: hidden;
}