.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  background: transparent;

  &__bcoin-dark {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 18px;
    gap: 12px;
    width: 174px;
    height: 50px;
    background: #33363b;
    border: 1px solid #c1c2c4;
    font-family: 'IBM Plex Mono', monospace;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #c1c2c4;
    transition: all 0.2s ease-out;

    &:hover {
      box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
      transition: all 0.2s ease-out;
    }
  }

  &__bcoin {
    &-contact {
      display: flex;
      height: 52px;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      border: 1px solid transparent;
      border-image: linear-gradient(
          330deg,
          rgba(255, 255, 255, 0.07),
          rgba(255, 255, 255, 0.51)
        )
        1;
      background: rgba(255, 255, 255, 0.05);
      color: #ffffff;
      font-family: 'IBM Plex Mono', monospace;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__bcoin-blue-xl {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px;
    gap: 32px;
    width: 100%;
    height: 64px;
    background: #2979ff;
    font-family: 'IBM Plex Mono', monospace;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    transition: all 0.2s ease-out;

    &:hover {
      box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
      transition: all 0.2s ease-out;
    }

    .order-tooltip {
      display: flex;
      opacity: 0;
      width: 100%;
      position: absolute;
      background-color: #444;
      color: white;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 16px;
      font-family: 'IBM Plex Mono', monospace;
      transition:
        opacity 0.3s ease-in-out,
        visibility 0.3s ease-in-out;
    }

    &.disabled {
      box-shadow: none;
      cursor: auto;
      opacity: 0.4;

      &:hover .order-tooltip {
        display: flex;
        opacity: 1;
        visibility: visible;
      }
    }

    &.disabled &:hover .order-tooltip {
      display: flex;
      opacity: 1;
    }
  }

  // Rounded btn
  &__rounded-blue {
    border: none;
    padding: 10px 30px;
    background: linear-gradient(
      270deg,
      #2979ff 0%,
      rgba(41, 121, 255, 0.67) 100%
    );
    box-shadow: 0px 4px 8px rgba(41, 121, 255, 0.3);
    border-radius: 38px;
    color: white;
    font-weight: bold;
    font-size: 16px;

    & span {
      font-size: large;
      margin-right: 10px;
    }

    &:hover {
      background: #2979ff;
    }

    &:disabled {
      background: linear-gradient(
        270deg,
        #b0b7c4 0%,
        rgba(101, 107, 116, 0.67) 100%
      );
      box-shadow: 0px 4px 8px rgba(101, 107, 116, 0.3);
    }
  }

  &__rounded-red {
    border: none;
    padding: 10px 30px;
    background: linear-gradient(270deg, #e76666 0%, #dc1919 100%);
    box-shadow: 0px 4px 8px rgba(41, 121, 255, 0.3);
    border-radius: 38px;
    color: white;
    font-weight: bold;
    font-size: 16px;

    & span {
      font-size: large;
      margin-right: 10px;
    }

    &:hover {
      background: #e76666;
    }

    &:disabled {
      background: linear-gradient(
        270deg,
        #b0b7c4 0%,
        rgba(101, 107, 116, 0.67) 100%
      );
      box-shadow: 0px 4px 8px rgba(101, 107, 116, 0.3);
    }
  }

  &__log-out {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    text-align: center;
    color: #ffffff;
    transition: all 0.2s ease-out;

    &:hover {
      color: #3d67ac;
    }
  }

  &__rounded-grey {
    background: linear-gradient(
      270deg,
      #b0b7c4 0%,
      rgba(101, 107, 116, 0.67) 100%
    );
    box-shadow: 0px 4px 8px rgba(165, 165, 165, 0.3);
    border-radius: 38px;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    padding: 0 1.2rem;
    height: 2.25rem;

    &__long {
      @extend .btn__rounded-grey;
      font-size: 0.875rem;
    }

    &:disabled {
      background: linear-gradient(
        270deg,
        #d3d3d3 100%,
        rgba(101, 107, 116, 0.67) 0%
      );
    }
  }

  &__rounded-blue-wide {
    @extend .btn__rounded-blue;
    padding: 0.75rem 1.75rem;
    width: 8rem;
  }

  &__rounded-grey-wide {
    @extend .btn__rounded-grey;
    padding: 0.75rem 1.75rem;
    width: 8rem;
    height: 100%;
  }

  &__rounded-blue-narrow {
    @extend .btn__rounded-blue;
    padding: 10px 20px;
  }

  // google login btn
  &__google-log {
    color: #2979ff;
    font-weight: bold;
    font-size: 24px;
    border-radius: 36px;
    border: 3px solid #2979ff;
    padding: 6px 40px;

    & span {
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 5px #2979ff inset;
    }

    &:disabled {
      display: none;
    }
  }

  // common log btn blue and big
  &__log-in {
    @extend .btn__rounded-blue;
    min-width: 360px;
    padding: 20px;
    font-size: 24px;
    box-shadow: 0px 25px 20px rgb(41 121 255 / 30%);

    &:disabled {
      box-shadow: 0px 20px 30px rgba(131, 154, 192, 0.78);
    }
  }

  //blue rectangle btn
  &__rectangle {
    background: #2979ff;
    box-shadow: 0px 4px 6px rgba(41, 121, 255, 0.2);
    border-radius: 5px;
    padding: 9px 30px;
    font-weight: 400;
    font-size: 15.36px;
    color: white;
    white-space: nowrap;

    &:hover {
      box-shadow: 0px 4px 6px rgba(41, 121, 255, 0);
    }
  }

  &__rectangle-narrow {
    @extend .btn__rectangle;
    padding: 9px 20px;
  }

  // square small blue
  &__icon-blue {
    background: #2979ff;
    height: 14px;
    width: 14px;
    padding: 0;
    border-radius: 2px;
    font-weight: 200;
    font-size: 13px;
    color: white;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      transform: scale(1.15);
    }

    &:disabled {
      display: none;
    }
  }

  // square small red
  &__icon-red {
    @extend .btn__icon-blue;
    background: #dc1919;
  }

  // Reload anuko data string btn
  &__string--bold--blue {
    background: none;
    font-size: 14px;
    font-weight: 700;
    color: rgba(41, 121, 255, 1);
    height: min-content;

    &.active {
      color: rgba(11, 46, 105, 1);
    }

    &:hover,
    &:active {
      color: rgba(11, 46, 105, 1);
    }

    &:disabled {
      display: none;
    }
  }

  &__string--bold--red {
    @extend .btn__string--bold--blue;
    font-size: 15.38px;
    color: #e76666;

    &:hover,
    &:active {
      color: #e76666;
    }
  }

  // Generate excel report string btn
  &__string--underlined--grey {
    text-decoration: underline;
    font-size: 12px;
    color: #737373;
    background: transparent;
    font-weight: 400;

    &:hover {
      transform: scale(1.05);
    }

    &:disabled {
      display: none;
    }
  }

  // Add column
  &__string--small--blue {
    font-size: 12px;
    font-weight: normal;
    color: #2979ff;

    & span {
      margin-right: 5px;
    }

    &:hover {
      font-weight: 500;
    }

    &:disabled {
      display: none;
    }
  }

  // Cancel on the data picker
  &__string--normal--grey {
    border: none;
    @extend .btn__string--bold--blue;
    font-weight: 500;
    color: rgba(115, 115, 115, 1);
    font-size: 16px;

    &:hover {
      color: rgba(115, 115, 115, 1);
    }
  }

  &__string--thin--grey {
    @extend .btn__string--normal--grey;
    font-weight: 400;
    font-size: 14px;
  }

  &__string--black--grey {
    @extend .btn__string--normal--grey;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }

  // butSave
  &__square-white {
    background: #ffffff;
    padding: 5px 15px;
    font-weight: bold;
    font-size: 14px;
    color: #2979ff;
  }

  &__rounded-white {
    background-color: #ffffff;
    border: 2px solid #2979ff;
    border-radius: 38px;
    color: #2979ff;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 30px 10px 30px;
  }

  // butSave Saved
  &__string-check-saved {
    font-weight: 500;
    font-size: 14px;
    color: #0b2e69;

    & span {
      margin-right: 5px;
    }
  }

  &__icon {
    &:hover {
      transform: scale(1.15);
    }
  }

  &__tab {
    padding: 8px 7px;
    min-width: 106px;
    background: #ffffff;
    border: 1px solid #2979ff;
    box-sizing: border-box;
    border-radius: 12px 12px 0px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2979ff;
    text-decoration: none;

    &.active {
      color: #95a1b9;
      border: 1px solid #95a1b9;
      background-color: #fafafa;
    }
  }
}
