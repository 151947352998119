.checkoutForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h2 {
    text-align: center;
    font-family:
      IBM Plex Mono,
      monospace;
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    color: #ffffff;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 16px;
    height: 100%;

    input {
      padding: 12px 15px;
      border: 1px solid transparent;
      border-image: linear-gradient(
          330deg,
          rgba(255, 255, 255, 0.07),
          rgba(255, 255, 255, 0.51)
        )
        1;
      background-color: transparent;
      font-family:
        IBM Plex Mono,
        monospace;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: rgb(255, 255, 255);
      outline: none;

      &:focus {
        border: 1.5px solid #ffffff;
      }

      &::placeholder {
        font-family:
          IBM Plex Mono,
          monospace;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &.error {
        border-color: red !important;
        border-image: none;
      }

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px transparent inset;
        -webkit-text-fill-color: rgb(255, 255, 255);
        transition: background-color 5000s ease-in-out 0s;
      }

      &.userName {
        user-select: none;
        color: #ffffffb5;
      }
    }

    .sizeCharts {
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: 4px;

      span {
        font-family:
          IBM Plex Mono,
          monospace;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: rgba(255, 255, 255, 0.8);
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .propertySelector {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      position: relative;

      &:nth-child(4) {
        padding-top: 20px;
      }

      .label {
        font-family:
          IBM Plex Mono,
          monospace;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        white-space: nowrap;
      }

      .options {
        display: flex;
        gap: 12px;
        width: 70%;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 44px;
          padding: 12px 0;
          border: 1px solid transparent;
          border-image: linear-gradient(
              330deg,
              rgba(255, 255, 255, 0.07),
              rgba(255, 255, 255, 0.51)
            )
            1;
          background: #ffffff05;
          color: rgba(255, 255, 255, 0.8);
          font-family: IBM Plex Mono;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          flex: 1 1 0;

          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            border-image: linear-gradient(
                330deg,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 1)
              )
              1;
          }

          &.active {
            border-color: #ffffff;
            background: #ffffff;
            color: #2979ff;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      height: 100%;
      align-items: flex-end;

      .backBtn,
      .confirmBtn {
        padding: 16px 32px;
        box-sizing: border-box;
        border: none;
        font-family: IBM Plex Mono;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        cursor: pointer;
        transition: 0.3s;

        &.backBtn {
          background: rgba(255, 255, 255, 0.05);
          color: white;
          border: 1px solid transparent;
          border-image: linear-gradient(
              330deg,
              rgba(255, 255, 255, 0.07),
              rgba(255, 255, 255, 0.51)
            )
            1;

          &:hover {
            background: #444;
          }
        }

        &.confirmBtn {
          background: #007bff;
          color: white;
          border: 1px solid #007bff;
          flex-grow: 1;

          &:hover {
            box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
            transition: all 0.2s ease-out;
          }
        }
      }
    }
  }
}

.inputWithIcon {
  position: relative;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    padding-right: 40px !important;
  }

  .icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    fill: rgba(255, 255, 255, 0.5);
    transition: fill 0.3s ease;

    &:hover {
      fill: rgba(255, 255, 255, 1);
    }
  }
}
