.sickness-list {
    box-sizing: content-box;
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            color: #0B2E69;
            font-style: normal;
            font-weight: 500;
            font-size: .925rem;
            border-bottom: 2px solid rgba(204, 214, 235, 1);

            th {
                padding: 1rem;
                text-align: start;

                &:first-of-type {
                    width: 15%;
                }

                &:nth-of-type(2) {
                    width: 15%;
                    text-align: center;
                }

                &:nth-of-type(3) {
                    width: 40%;
                    text-align: center;
                }

                &:nth-of-type(4) {
                    width: 15%;
                    text-align: center;
                }

                &:last-of-type {
                    width: 15%;
                    text-align: end;
                }
            }
        }
    }

    tbody {
        font-size: .9rem;

        tr {
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
            background: #F3F7FF;
            border-bottom: 3px solid #fff;

            td {
                padding: 1rem;
                text-align: start;

                &:first-of-type {
                    width: 15%;
                }

                &:nth-of-type(2) {
                    width: 15%;
                    text-align: center;
                }

                &:nth-of-type(3) {
                    width: 40%;
                    text-align: start;
                }

                &:nth-of-type(4) {
                    width: 15%;
                    text-align: center;
                }

                &:last-of-type {
                    width: 15%;
                    text-align: end;
                }
            }
        }

        .subRow-1 {
            background: #FAFDFF;
        }

        .editRow {
            background: #FFFAF1;
        }

        .editSubRow {
            background: rgba(255, 250, 241, .4);
        }
    }
}

.sickness_btn_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .5rem;
}

.table_btn_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: .5rem;
    width: 100%;

    .innerBtnWrapper {
        display: flex;
        justify-content: flex-end;
        width: 30%;
        gap: .5rem;
    }
}