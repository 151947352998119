.controls_panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    @media screen and (max-width: 960px) {
        align-items: flex-end;
        padding: 0 1.5rem;
    }
}

.controls_panel_left,
.controls_panel_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.controls_panel_left {
    justify-content: flex-start;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }
}

.controls_panel_right {
    justify-content: flex-end;

    @media screen and (max-width: 960px) {
        flex-direction: column-reverse;
        gap: 1rem;
        align-items: flex-end;
    }
}

.total_items {
    font-size: 12px;
    color: #737373;
}