@import '../../../styles/bcoin-animations.scss';

@mixin FlexColumnStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

$primary: #2979ff;
$success: #13b873;
$text_light: #ffffff;

.slick-slide {
  margin-right: 40px;
}

.how-to-get-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 540px;
  height: 100vh;
  animation-name: fadeInBlock;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
}

.how-to-get-list {
  position: relative;
  top: 132px;
  right: 0;
  left: 340px;
  box-sizing: border-box;
  width: 1200px;
  height: 100%;
  z-index: 12;
  .slick-list {
    overflow: visible;
  }
}

.card {
  @include FlexColumnStart;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 38px 40px;
  gap: 24px;
  width: 380px;
  min-width: 380px;
  min-height: 520px;
  background: linear-gradient(
    114.73deg,
    rgba(209, 209, 209, 0.2) -7.11%,
    rgba(188, 188, 188, 0.05) 116.12%
  );
  backdrop-filter: blur(5px);
  border: 1px solid transparent;
  border-image: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.51),
      rgba(255, 255, 255, 0.07)
    )
    1;
  position: relative;

  @media screen  and (max-width: 1536px) {
    min-height: 440px;
    padding: 24px;
  }

  .card-task-completed {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.72);
    backdrop-filter: blur(5px);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;

    svg {
      width: 33px;
      height: 33px;
    }

    h4 {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      color: #ffffff;
    }
  }

  &:hover {
    .card-btn-container {
      display: flex;
    }
    .card-you-get-container {
      display: none;
    }
  }
}

.card-txt-container {
  @include FlexColumnStart;
  gap: 24px;
  width: 100%;
}

.card-title {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #ffffff;

  @media screen and (max-width: 1536px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.card-description,
.card-link,
.card-additional-data {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.84);
}

.card-description,
.card-link {
  font-size: 15px;
  line-height: 20px;
}

.card-link {
  display: inline-block;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 270px;
  text-overflow: ellipsis;
}

.card-additional-data {
  font-size: 12px;
  line-height: 18px;
}

.card-you-get-container {
  @include FlexColumnStart;
  gap: 12px;
}

.card-you-get-title {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.84);
  opacity: 0.4;
}

.card-you-get-value-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: 12px;
}

.card-you-get-value {
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;
  color: #ffffff;
}

.pointer-bottom {
  position: absolute;
  left: 74%;
  bottom: 4px;
  animation-name: fadeInBlock;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;

  img {
    max-width: 116px;
  }

  @media screen and (min-width: 1670px) {
    bottom: 0;
  }
}

.pointer-modal {
  position: absolute;
  left: -20px;
  bottom: 20px;
  animation-name: fadeInBlock;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  transition: all 0.75s ease;
  transform: rotate(90deg);

  img {
    width: 112px;
  }
}

.card-btn {
  padding: 16px 0;
  width: 100%;
  max-height: 108px;
  height: 108px;
  border: none;
  color: $text_light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  &-complete {
    background-color: $primary;
  }
  &-have-completed {
    background-color: $success;
  }
  &-container {
    display: none;
    gap: 8px;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 8px;
    left: 8px;
    right: 8px;
  }
  &-icon {
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
  }
}
