.toggle-slider-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.toggle-slider-title {
    font-size: 13px;
    color: #737373;

    @media screen and (max-width: 920px) {
        font-size: 12px;
    }
}

.toggle-slider-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.slider-checkbox {
    height: 0;
    width: 0;
    display: none;
}

.slider-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 36px;
    height: 20px;
    margin: 0;
    border: 1px solid #737373;
    border-radius: 20px;
    position: relative;
    transition: background-color .2s;
}

.slider-label .slider-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: #737373;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.slider-checkbox:checked+.slider-label .slider-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}