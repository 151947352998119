.container {
  position: relative;
  margin-left: 5.625rem;
  padding: 0 2rem 2rem calc(2rem + 10px);
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (max-width: 1280px) {
    margin-left: 0;
    padding: 0 1rem 2rem 1rem;
    width: calc(100% - 2rem);
  }

  @media screen and (min-width: 1670px) {
    width: 77vw;
  }
}

.time_helper {
  padding: 6px 10px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  color: #737373;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.1s ease;
  &:hover {
    border: 1px solid #2579ff;
    background-color: #e9f1ff;
    color: #2579ff;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
}

.infoRow {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0;
  top: 62px;
  box-sizing: border-box;
  padding: 0px 2rem 0px calc(2rem + 10px);
}

.infoRowTime {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0b2e69;
}

.infoRowDate {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #2979ff;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  padding-top: 2rem;
}

.progressBar {
  padding: 1.25rem;

  @media screen and (min-width: 1670px) {
    display: flex;
    justify-content: center;
  }
}

.data {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

.form {
  padding: 1rem 2rem;
  background: #ffffff;
  box-shadow: 0px 35px 73px rgba(32, 32, 35, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  &.onFocus {
    border: 2px solid rgba(41, 121, 255, 1);
  }

  @media screen and (max-width: 960px) {
    height: 95%;
  }
}

.formContent {
  display: grid;
  grid-template-columns: 3fr 5.5fr;
  gap: 1rem;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media screen and (min-width: 1670px) {
    gap: 1.25rem;
  }
}

.formContentCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 24rem;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: fit-content;
  }
}

.formContentLine {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  width: 16rem;

  @media screen and (min-width: 1670px) {
    justify-content: space-between;
  }
}

.formInputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formFooter {
  display: flex;
  justify-content: space-between;
}

.formFooterEnd {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  position: relative;
  align-items: center;
}

.formFooterSingleChild {
  display: flex;
  justify-content: flex-end;
}

.textAreaWrapper {
  box-sizing: border-box;
  width: 100%;
  height: 17rem;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 12rem;
  }
}

.textAreaWrapperEdit {
  box-sizing: border-box;
  width: 100%;
  height: 16rem;
  margin-top: 37px;
}

.textArea {
  box-sizing: border-box;
  outline: none;
  border: 1px solid #dee3ee;
  border-radius: 2px;
  padding: 0.5rem;
  resize: none;
  width: 100%;
  height: 100%;
}

.textAreaLabel {
  padding: 0;
  margin: 0;
}

.textAreaTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #878787;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 0;
  margin-bottom: 0.425rem;
}

.textAreaLength {
  color: #bcbcbc;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  cursor: context-menu;
}

.dateValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.circularProgressBarTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #2979ff;
}

.circularProgressBarValue {
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.25rem;
  color: #737373;
}

.datePicker {
  display: flex;
  flex-direction: column;
  height: 24rem;
  padding: 1rem 2rem;
  background: #ffffff;
  box-shadow: 0px 35px 73px rgba(32, 32, 35, 0.1);
  border-radius: 10px;

  @media screen and (max-width: 960px) {
    justify-content: flex-start;
    height: 95%;
    padding: 1rem;
  }
}

.datePickerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.datePickerSummary {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.datePickerSummaryBtn {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
}

.datePickerSummaryCol {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0b2e69;
}

.datePickerInput {
  box-sizing: border-box;
  border: 1px solid #dee3ee;
  border-radius: 3px;
  outline: none;
  width: 5.875rem;
  height: 100%;
  padding: 0.5rem;
}

.monthTotal {
  display: flex;
  justify-content: space-between;
  width: 9.12rem;
}

.labelEmpty {
  margin: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.buttonArea {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip_wrapper {
  display: flex;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 100 !important;
  opacity: 0;
  padding: 0 !important;
  max-width: 515px !important;
  transition: opacity 0.5s ease;
}

.tooltip_wrapper[data-show='true'] {
  opacity: 1;
}

.tooltip {
  display: flex;
  position: relative;
  padding: 24px 22px;
  border-radius: 6px;
  background-color: white;
  align-items: start;
  width: 100%;
  gap: 26px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }

  &_image {
    display: flex;
    max-width: 200px;

    img {
      width: 100%;
      height: auto;
    }

  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -24px;
    left: -24px;
    min-width: 48px;
    height: 48px;
    background: #186cf74d;
    border-radius: 50%;
    z-index: -1;
    animation: pulse 2s infinite;
  }

  &::after {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    min-width: 30px;
    height: 30px;
    background: #186cf7;
    border-radius: 50%;
  }

  &_content {
    max-width: 243px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    p {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__list p::before {
      content: '';
      border-radius: 50%;
      min-width: 6px;
      height: 6px;
      background-color: #2579ff;
    }
  }
}

.tooltip h4 {
  margin: 0 0 16px;
  font-size: 16px;
  font-weight: 500;
}

.tooltip li {
  margin-bottom: 8px;
  font-size: 13px;
  list-style-type: none;
}

.tooltipButtons {
  display: flex;
  gap: 25px;
  justify-content: center;
  margin-top: 24px;
  cursor: pointer;
}

.dont_show_button {
  color: #737373;
  border: none;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
}

.ok_button {
  border: none;
  padding: 7px 20px;
  background: linear-gradient(
    270deg,
    #2979ff 0%,
    rgba(41, 121, 255, 0.67) 100%
  );
  box-shadow: 0px 4px 8px rgba(41, 121, 255, 0.3);
  border-radius: 38px;
  color: white;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: #2979ff;
  }
}
